import { useState } from "react";
import { SubMenu } from './SubMenu';

import { actions } from '../Options';

export function Menu(props) {

    const [menuOption, setMenuOption] = useState('');
    const [subOption, setSubOption] = useState('');

    const {
        choices,
        setChoices
    } = props;


    let menuoptions = actions.map(action => 

        <button 
        id={action}
        onClick={handleClick}
        key={action}
        style={{ backgroundColor: action===menuOption ? 'lightyellow' : ''}}

        >
            
            {action}</button>
        
    );

    function handleClick(e) {
        setMenuOption(e.target.id);
        setSubOption('');
    }

    return(
        <div id='menucontainer' className='shadow'>


        {menuoptions}


        {menuOption !== '' && <SubMenu  menuOption={menuOption} 
                                        setMenuOption={setMenuOption}
                                        subOption={subOption} 
                                        setSubOption={setSubOption}
                                        choices={choices}
                                        setChoices={setChoices}/> }




        </div>

    )


}