import sound from './mixkit-fireworks-whooshes-and-bangs-524.wav';

const audio = new Audio(sound);

export function NextMonth(props) {

    const { simdata,
            setSimdata,
            setOutcomesVisible,
            setChoices,
            setWinVisible
        } = props;

    const newMonth = simdata.currentMonth + 1;
    //console.log(`New month ${newMonth}`);

    function incrementMonth() {

        setSimdata({
            ...simdata,
            currentMonth: newMonth
        });

        setOutcomesVisible(false);

        setChoices({
            first: '',
            second: ''
        })

        window.scroll(0,0);

       if (simdata.winCondition) {
            setWinVisible(true);
            audio.play()
        }

    }

    return (

        <div id = 'nextmonthdiv'>
        <p><strong>When you're ready to move on, click below</strong></p>
        <br />
        <button id='nextmonth' onClick={incrementMonth}>Move to next month</button>
        </div>


    )




}

