export const people = ['CEO', 'SVP', 'COO', 'CoS', 'Ms Smiley/Dr Brain', 'Dr Head', 'Mr Walker', 'Dr Tripman', 'Mr Kuteroff/Dr Blades', 'Dr Hacker', 'Ms Body/Dr Pepper', 'Dr Hertz'];
export const programs = ['Mental Health', 'Surgery', 'Ambulatory Care', 'Medicine'];
export const supports = ['Facilities', 'Diagnostic Imaging', 'Finance', 'Pharmacy', 'Medical Staff Office', 'Communications', 'Decision Support', 'Food Services'];
export const meetings = ['FMC', 'SLT', 'MAC', 'Board of Directors'];
export const comms = ['Write a Memo', 'Present at Management Meeting', 'Hold a Town Hall']
export const others = ['Informal networking', 'Grassroots advocacy', 'Personal social media'];

export const actions = ['Individual meeting', 'Program meeting', 'Engage support areas', 'Attend a formal meeting', 'Communications tactics', 'Other actions'];

export const allOptions = people.concat(programs, supports, meetings, comms, others);




