export function InstructionsModal( {setInstructionsVisible} ) {

function handleClick() {
    setInstructionsVisible(false);
}

return(
    <div id="instructionsModal" className="modal">

    <div className="modal-content">
    <span className="close" id="closeinstructions" onClick={handleClick}>&times;</span>
    <h3>Simulation Instructions</h3>
    <p>Your goal as a group is to introduce a new clinical service within Odysseus Healthcare. <br/><br/>This is an entirely fictious organization and any resemblance to existing healthcare organizations is entirely coincidental!<br/><br/> 
        The purpose of the simulation is to help you learn about practical organizational transformation.
        These instructions will provide you with some useful background information. <br/><br/><br/>
        
        The simulation starts at the beginning of the financial year. Each round represents one month. You can take as long as you need. During each round, you must undertake two actions. The order of actions is important, so think about which you assign as your first and second action. 
        After you have made your selections, you can submit them, and the outcomes will be presented to you. Read these carefully as they may include helpful hints or direction on how to proceed. 
        <br/><br/>
        When you are navigating through the simulation, please only use the controls within the simulation. Use of buttons on the browser (back, refresh etc) may cause unpredictable behaviour. 
        You can scroll up and down or use the navigation bar on the left to jump to sections of the simulation. <br/><br/>You can see Odysseus Healthcare's organization chart by clicking on the Org Chart box.<br/>If you would like to see all of your decisions in previous rounds, then click the History box.<br/>
        <br/><br/>
        <strong>FMC</strong> is the Financial Management Committee. It consists of the hospital's management team, including representatives from the Senior Leadership Team. It is the first part of the organizational approval process. In order to have your idea considered by FMC, you will need to produce a Briefing Note. You will be able to obtain information relevant to your SBAR by engaging the program as well as support areas within the organization. The nature of your clinical service will impact the kind of information you need to include. <br/><br/>
        <strong>SLT</strong> is the Senior Leadership Team. This group consists of the Executives in the organization, including the CEO, VPs and COOs. Once your service has been approved by FMC, this will be your next step to obtain approval.<br/><br/>
        <strong>MAC</strong> is the Medical Advisory Committee. This is chaired by the Chief of Staff. In this simulation, once a service has been approved by SLT and resources confirmed, it will need to be endorsed by MAC as well before going to the Board of Directors.<br/><br/> 
        The Board of Directors is ultimately accountable for the organization. In this simulation, because you are introducing a new clinical service, the Board's approval will be required. (In reality, there may be other approval processes required including from the Ministry of Health and/or Ontario Health, but we are simplifying this here). 
    </p>
    </div>  
    </div>
)

}