import React, { useState } from 'react';
import './App.css';

import { Main } from './Main';
import  { assignDisease } from './DataInit';



function App() {

  // initial disease assignment at top level of app, this shouldn't change unless user refreshes browser and starts sim afresh
  const getDisease = assignDisease();
  const [disease, setDisease] = useState(getDisease);





  return ( 
    <Main 
      disease={disease}
      setDisease={setDisease}
    />
  );
}

export default App;

