import orgchart from './OrgChart.jpg';

export function OrgChartModal({setOrgChartVisible}) {

function handleClick() {
    setOrgChartVisible(false);
}




    return (

    <div id="orgchartModal" className="modal">

    <div className="modal-content">
      <span className="close" id="closeorgchart" onClick={handleClick}>&times;</span>
      <h3>Organizational Chart</h3>
      <img src={orgchart} alt='organization chart'/>
    </div>  
</div>


    )
}