import React from "react";
import { db } from '../DatabaseHandling';
import { doc, setDoc } from "firebase/firestore";


export function NavBox(props) {


    const { item, icon, setInstructionsVisible, setOrgChartVisible, setIsLoggedIn, choiceMatrix, disease, simdata, email } = props;

    function handleClick() {

        switch (item) {

            case 'Instructions':
                setInstructionsVisible(true)
                break;
            case 'Org Chart':
                setOrgChartVisible(true);
                break;
            case 'Briefing Note':
                // check sbarcontainer exists
                const sbarcontainer = document.getElementById("sbarcontainer")
                if (sbarcontainer) sbarcontainer.scrollIntoView() ;
                break;
            case 'Clinical Service':
                window.scrollTo(0,0);
                break;
            case 'Decide':
                const menucontainer = document.getElementById("menucontainer")
                if (menucontainer) menucontainer.scrollIntoView() ;
                break;
            case 'History':
                const historycontainer = document.getElementById("decisionhistory")
                if (historycontainer) historycontainer.scrollIntoView() ;
                break;
            case 'Logout':
                setIsLoggedIn(false);

                setDoc(doc(db, "sessions", email), {
                    choices: choiceMatrix,
                    disease: disease,
                    simdata: simdata
                },
                { merge: true }
                );
            
                break;


            default:
        }



    
    }


    return (
        <div className='navbox' id={item} icon={icon} onClick={handleClick}>
        <p>
            {icon}
            &nbsp;
            {item}

        </p>
    </div>
    )
}
