


export function WinModal(props) {

    function handleClick() {
        window.location.reload(true)
    }

    return (

        <div id="winModal" className="modal">
        <div className="modal-content">
            <h3>Congratulations!</h3>
            <p>You successfully navigated through the complex journey of organizational transformation and got your new clinical service implemented.<br />
            <br />Hundreds of sufferers flock to Odysseus Healthcare to seek treatment for their disease, thanks to your efforts.<br />
            Now is the time to bask in your achievements, and reflect on what you have learnt.
            <br /><br />
            You completed the simulation in <span id="numOfMonths">{props.monthTotal}</span> months.
            <br />
            Your score was <span id="winScore">{100 - ((props.monthTotal - 10)*8)} </span>.
            <br /><br />
            Enjoy the fireworks in your honour!
            </p>
            <br /><br />
            <button onClick={handleClick}>Restart Simulation</button>
            <div className="fireworkscontainer">
                <div className="pyro">
                    <div className="before"></div>
                    <div className="after"></div>
                </div>
            </div>
        </div>

        </div>

    )

}