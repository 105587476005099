export function SelectedChoices(props) {

    const firstchoice = props.choices.first;
    const secondchoice = props.choices.second;
    const currentMonth = props.currentMonth;
    const months = props.months;






    return(
        <div id="decisionscontainer" className="shadow"> 
    
            <h3>Decisions for this month</h3>
    
    
    
        <div id="optionsandcalendar">
            <div id='currentmonthdiv'>
                <h5>Current month</h5>
                <p id="currentmonth">{months[(currentMonth - ((Math.floor(currentMonth/12)) * 12))]}</p>
        </div>
            <div id = 'selectedoptions'>
                <h4>Actions selected (in order)</h4>
                <ol>
                    <li id='selectedOption1'>{firstchoice}</li>
                    <li id='selectedOption2'>{secondchoice}</li>
                </ol>
            </div>
    
        </div>
        </div>
    
        )
    }

