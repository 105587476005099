
export function Disease(props) {

    const disease = props.disease;


    return(


        <div id= 'diseasedescription' className="shadow">
        <h3> Clinical Service</h3>
        <p>Your team would like to establish a new clinical service within Odysseus Healthcare to address <strong><span id='diseasename'>{disease.name}</span></strong>.<br /> <br />
            This is caused by <strong><span id='diseasecause'></span>{disease.cause}</strong> and leads to symptoms of <strong><span id='diseasesymptoms'>{disease.symptoms}</span></strong>.<br /><br />
            The treatment is <strong><span id='diseasetreatment'></span>{disease.treatment}</strong>.<br /><br /> <span id='diseasenotes'>{disease.notes}</span>
        </p>

        </div>



    )
}