import { Banner } from './BannerComponent/Banner';
import { NavBar } from './NavComponents/NavBar';
import { Disease } from './Disease';
import { SelectedChoices } from './SelectedChoices';
import { Menu } from './MenuComponents/Menu';
import { ConfirmChoices } from './ConfirmChoices';
import { useState } from 'react';
import { Outcomes } from './Outcomes';
import { NextMonth } from './NextMonth';
import { InstructionsModal } from './ModalComponents/InstructionsModal';
import { OrgChartModal } from './ModalComponents/OrgChartModal';
import { Sbar } from './Sbar';
import { History } from './History';
import { allOptions } from './Options';
import { WinModal } from './ModalComponents/WinModal';

import Login from './Login';
import Signup from './Signup';
import fireworksSound from './mixkit-fireworks-whooshes-and-bangs-524.wav';


import { startingSimData, months } from './DataInit';

const fireWorksAudio = new Audio(fireworksSound);
fireWorksAudio.loop = true;




export function Main(props) {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [newAccountShowing, setNewAccountShowing] = useState(false);

    const [email, setEmail] = useState('');

    const [choices, setChoices] = useState({first: '', second: ''});
    const [outcomesVisible, setOutcomesVisible] = useState(false);

    const [instructionsVisible, setInstructionsVisible] = useState(false);
    const [orgChartVisible, setOrgChartVisible] = useState(false);

    const [sbarVisible, setSbarVisible] = useState(false);

    const [historyVisible, setHistoryVisible] = useState(false);

    const [winVisible, setWinVisible] = useState(false)

    const { disease, setDisease } = props;

    let choicematrix = new Object();

    allOptions.map(o => {
    choicematrix[o] = [];
    })


    const [choiceMatrix, setChoiceMatrix] = useState(choicematrix);

    const [simdata, setSimdata] = useState(startingSimData);
        







    return (
        <div>
        <Banner/>




        {instructionsVisible && <InstructionsModal 
                                    setInstructionsVisible={setInstructionsVisible}/>}

        {orgChartVisible && <OrgChartModal 
                                setOrgChartVisible={setOrgChartVisible}/>}

        {winVisible && <WinModal
                        monthTotal ={simdata.currentMonth}
                        />}
  
  

        {isLoggedIn && <NavBar setInstructionsVisible={setInstructionsVisible} 
                setIsLoggedIn={setIsLoggedIn}
                choiceMatrix={choiceMatrix}
                setOrgChartVisible={setOrgChartVisible} 
                historyVisible={historyVisible}
                sbarVisible={sbarVisible}
                disease={disease}
                simdata={simdata}
                email={email}

                />
        }

        <div id="maincontainer">
        <div id="contentbox">

        {!isLoggedIn && !newAccountShowing && <Login setisLoggedIn={setIsLoggedIn} 
                                                    setNewAccountShowing={setNewAccountShowing} 
                                                    email={email} 
                                                    setEmail={setEmail} 
                                                    simdata={simdata} 
                                                    setSimdata={setSimdata} 
                                                    setHistoryVisible={setHistoryVisible} 
                                                    setChoiceMatrix={setChoiceMatrix}
                                                    setSbarVisible={setSbarVisible} 
                                                    setDisease={setDisease}/>}

        {newAccountShowing && !isLoggedIn && <Signup setIsLoggedIn={setIsLoggedIn} setNewAccountShowing={setNewAccountShowing}/>}


  
        {isLoggedIn && <Disease disease={disease}/>}
  
  

           
        {isLoggedIn && <SelectedChoices choices={choices} currentMonth={simdata.currentMonth} months={months}/>}

        {isLoggedIn && !outcomesVisible && <Menu choices={choices} setChoices={setChoices} />}

        {isLoggedIn && choices.first !== '' && choices.second !== '' && !outcomesVisible && <ConfirmChoices setOutcomesVisible={setOutcomesVisible}
                                                                                                choices = {choices}
                                                                                                setChoiceMatrix = {setChoiceMatrix}
                                                                                                choiceMatrix = {choiceMatrix}
                                                                                                setHistoryVisible = {setHistoryVisible}
                                                                                                simdata={simdata}
                                                                                                disease={disease}
                                                                                                email={email}

                                                                                                
                                                                                              
                                                                                />}


        {isLoggedIn && outcomesVisible && <Outcomes outcomesVisible={outcomesVisible} 
                                        simdata={simdata} 
                                        setSimdata={setSimdata} 
                                        choices={choices} 
                                        setChoices={setChoices} 
                                        disease={disease}
                                        setSbarVisible={setSbarVisible}
                            >

                                        <NextMonth simdata={simdata} 
                                                    setSimdata={setSimdata} 
                                                    setOutcomesVisible={setOutcomesVisible}
                                                    setChoices={setChoices}
                                                    setWinVisible={setWinVisible}
                                                    />

                                        </Outcomes>}

        
        {isLoggedIn && sbarVisible && <Sbar disease={disease} simdata={simdata.supportE}/>}

        {isLoggedIn && historyVisible && <History choiceMatrix={choiceMatrix}
                                    months={months}
                                    currentMonth={simdata.currentMonth}
        
        />}
        </div>
         </div>
       
        </div>
       
    )
};


