import { allOptions } from "./Options";
import sound from './mixkit-achievement-bell-600.wav';
import { db } from './DatabaseHandling';
import { doc, setDoc } from "firebase/firestore";


const submitChoiceAudio = new Audio(sound);

export function ConfirmChoices(props) {

    const { setOutcomesVisible,
            setChoiceMatrix,
            choiceMatrix,
            choices,
            setHistoryVisible,
            disease,
            simdata,
            email
    } = props;


    let zeroSetter = {};


    // update choice matrix
    // ensure 0 value for all choices not included
    function handleClick() {

        submitChoiceAudio.play();


        function notSelected(x) {
            return (x !== choices.first && x !== choices.second);
        }

        const toSetZeroes = allOptions.filter(notSelected);

        setOutcomesVisible(true);
        setHistoryVisible(true);

        //TODO: save data on confirming choices
        setDoc(doc(db, "sessions", email), {
            choices: choiceMatrix,
            disease: disease,
            simdata: simdata
        },
        { merge: true }
        );

/*
        const testfunction = () => {
            return {test: 0, test2: 1}
        }

        let testy = testfunction();
        */


        
        for (let i = 0; i < toSetZeroes.length; i++) {

            let n = toSetZeroes[i];

            zeroSetter[n] = choiceMatrix[n];

            zeroSetter[n].push(0);
        }
        //console.log(zeroSetter);

        


        setChoiceMatrix({
            ...choiceMatrix,
            [choices.first]: [...choiceMatrix[choices.first], 1],
            [choices.second]: [...choiceMatrix[choices.second], 2],
            ...zeroSetter
            

        });

        


    }


    return (


        <div id = 'submitchoices' className='shadow'>
        <button id="confirmchoicesbutton" onClick={handleClick}>Submit choices</button>
        </div>

    )

}