import { NavBox } from "./NavBox";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faSitemap, faThermometer, faCalendarCheck, faFileText, faHistory, faSignOut } from '@fortawesome/free-solid-svg-icons'

const bookIcon = <FontAwesomeIcon icon={faBook} />
const orgChartIcon = <FontAwesomeIcon icon={faSitemap} />
const thermometerIcon = <FontAwesomeIcon icon={faThermometer} />
const decideIcon = <FontAwesomeIcon icon={faCalendarCheck} />
const sbarIcon = <FontAwesomeIcon icon={faFileText} />
const historyIcon = <FontAwesomeIcon icon={faHistory} />
const signOutIcon = <FontAwesomeIcon icon={faSignOut} />




export function NavBar(props) {

    const { setInstructionsVisible, setOrgChartVisible, sbarVisible, historyVisible, setIsLoggedIn, choiceMatrix, disease, simdata, email } = props;

    return(

            <div id="navbar" className="shadow">

                <NavBox item='Instructions' setInstructionsVisible={setInstructionsVisible} icon={bookIcon}/>
                <NavBox item='Org Chart' setOrgChartVisible={setOrgChartVisible} icon={orgChartIcon}/>
                <NavBox item='Clinical Service' icon={thermometerIcon} />
                <NavBox item='Decide' icon={decideIcon}/>
                {sbarVisible && <NavBox item='Briefing Note' icon={sbarIcon}/>}
                {historyVisible && <NavBox item='History' icon={historyIcon}/>}
                <NavBox item='Logout' 
                icon={signOutIcon} 
                setIsLoggedIn={setIsLoggedIn} 
                choiceMatrix={choiceMatrix}
                disease={disease}
                simdata={simdata}
                email={email}
                />

            </div>




    )

}