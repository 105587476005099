
import { EvaluateChoice } from "./EvaluateChoice"

export function Outcomes(props) {

    const {
        simdata,
        setSimdata,
        choices,
        setChoices,
        disease,
        setSbarVisible
    } = props;

 


    return (

        <div id="resultscontainer" className="shadow">
            <h3>Outcomes</h3>
            <EvaluateChoice
                    simdata={simdata}
                    setSimdata={setSimdata}
                    choices={choices}
                    setChoices={setChoices}
                    disease={disease}
                    setSbarVisible={setSbarVisible}
            
            />

        <br />
        <br />
        {props.children}
         </div>  
    )   
}