import { allOptions } from './Options';


/*

const TableCells = (props) => {

    const {
        choiceMatrix,
        action } = props;

    const selectedCells = choiceMatrix[action]


    let cellsToDisplay = selectedCells.map(cell => 

        <td>
        {cell === 0 ? ' ' : cell } 
        </td>
        
        
    );

    return (
        <Fragment>
        {cellsToDisplay}
        </Fragment>
    
    )


}

const TableRow = (props) => {

    const {
        action,
        choiceMatrix,
    } = props;

    return (
        <tr><td>{action}</td>
        
        <TableCells choiceMatrix={choiceMatrix} action={action}/>

            
        
        </tr>
    )

}


export function History(props) {


    const {
        choiceMatrix,
        months,
        currentMonth
    } = props;

    function returnMonth(m) {
        return months[(m - ((Math.floor(m/12)) * 12))]
    }

    function monthsHeader(m) {
        let mh = [];
        for (let i = 0; i <= m; i++) {
            mh.push(returnMonth(i))
        }
        return(mh);
    }

    const mh = monthsHeader(currentMonth)


    return (
        <div id='decisionhistory' className="shadow">
        <h3>Decision History</h3>
        <table id='decisiontable'>
            <thead>
                <th>Action</th>

                {mh.map(m => 
                    <th>{m}</th>)}



            </thead>
            <tbody>
            
            {allOptions.map(p => 
                <TableRow action={p} choiceMatrix={choiceMatrix}/>
                )}
            


                </tbody>

        </table>
    </div>
    )

}
/*

*/


const TableCells = (props) => {
  const { choiceMatrix, action } = props;
  const selectedCells = choiceMatrix[action];

  let cellsToDisplay = selectedCells.map((cell, index) => (
    <td key={index}>
      {cell === 0 ? ' ' : cell}
    </td>
  ));

  return <>{cellsToDisplay}</>;
};

const TableRow = (props) => {
  const { action, choiceMatrix } = props;

  return (
    <tr>
      <td>{action}</td>
      <TableCells choiceMatrix={choiceMatrix} action={action} />
    </tr>
  );
};

export function History(props) {
  const { choiceMatrix, months, currentMonth } = props;

  function returnMonth(m) {
    return months[m % 12];
  }

  function monthsHeader(m) {
    let mh = [];
    for (let i = 0; i <= m; i++) {
      mh.push(returnMonth(i));
    }
    return mh;
  }

  const mh = monthsHeader(currentMonth);

  return (
    <div id='decisionhistory' className="shadow">
      <h3>Decision History</h3>
      <table id='decisiontable'>
        <thead>
          <tr>
            <th>Action</th>
            {mh.map((m, index) => (
              <th key={index}>{m}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allOptions.map((p, index) => (
            <TableRow key={index} action={p} choiceMatrix={choiceMatrix} />
          ))}
        </tbody>
      </table>
    </div>
  );
}


