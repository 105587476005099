import React from "react";

import audio from './mixkit-unlock-game-notification-253.wav';

const makeChoiceAudio = new Audio(audio);

export function SetChoices(props) {


    const { choices, setChoices, subOption, setSubOption, setMenuOption } = props;



    function resetMenu() {

        setSubOption('');
        setMenuOption('');

    }

    function handleClick(e) {

        makeChoiceAudio.play();

        if (e.target.id === 'firstChoiceButton') {

            if (choices.second === subOption) {
                console.log('duplicate');
                setChoices( {
                    first: subOption,
                    second: ''
                })
            } else {

            setChoices( {
                ...choices,
                first: subOption
            }

            )}
        }

        if (e.target.id === 'secondChoiceButton') {
            if (choices.first === subOption) {
                setChoices( {
                    first: '',
                    second: subOption
                })
            } else {
            setChoices( {
                ...choices,
                second: subOption
            }
            )
        }
        }

        resetMenu();

    }
    

    return (

        <div id = 'confirmchoices' className='menu'>
        <button id="firstChoiceButton" onClick={handleClick}>Set as first choice</button>
        <button id="secondChoiceButton" onClick={handleClick}>Set as second choice</button>

        </div>

    )


}