import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";



export const firebaseConfig = {
    apiKey: "AIzaSyCZEnudnGZOg6J1XsQqvCbgmEfpdp86XTY",
    authDomain: "transform-ab34b.firebaseapp.com",
    projectId: "transform-ab34b",
    storageBucket: "transform-ab34b.appspot.com",
    messagingSenderId: "392370155351",
    appId: "1:392370155351:web:bf25d7edd5c4c5bccbd812",
    measurementId: "G-NMJQGQSTLF"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
