import React, {useState} from 'react';
import {  signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from './DatabaseHandling';



 
const Login = (props) => {
    //const navigate = useNavigate();

    const { email,
            setEmail,
            simdata,
            setSimdata } = props;
    
    const [password, setPassword] = useState('');
    const [errmsg, setErrmsg] = useState('');

    const { setisLoggedIn,
            setNewAccountShowing,
            setHistoryVisible,
            setChoiceMatrix,
            setDisease,
            setSbarVisible
        } = props;

    const onLogin = async (e) => {
        e.preventDefault();
        
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
        
            console.log(user);
        
            // Set isLoggedIn state
            setisLoggedIn(true);
        
            // Restore session data if there is an existing session
            // Check if existing session data
            const docRef = doc(db, "sessions", email);
        
            const docSnapshot = await getDoc(docRef);
        
            if (docSnapshot.exists()) {
            //console.log("Document data:", docSnapshot.data());
            
                setSimdata(docSnapshot.data().simdata);
                setChoiceMatrix(docSnapshot.data().choices);
                setHistoryVisible(true);

                //Set disease if existing session data
                setDisease(docSnapshot.data().disease);

                //set sbarvisible if support areas engaged
                if (docSnapshot.data().simdata.supportE.length > 0) {
                    setSbarVisible(true);
                }

            } else {
            console.log("No such document!");
            }
        
            // Record new timestamp for session
            await setDoc(doc(db, "sessions", email), { timestamp: serverTimestamp() }, { merge: true });
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
            setErrmsg(errorMessage);
        }
        };




    const createNewAccount = () => {
        setNewAccountShowing(true);
    }

 
    return(

                    <div id='logincontainer' className='shadow'>                                            
      <h2> Sign in</h2>                                                           
                        <form>                                              
                            <div>
                                <label htmlFor="email-address">
                                    Email address &nbsp;
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"                                    
                                    required                                                                                
                                    placeholder="Email address"
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </div>

                            <div>
                                <label htmlFor="password">
                                    Password &nbsp;
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"                                    
                                    required                                                                                
                                    placeholder="Password"
                                    onChange={(e)=>setPassword(e.target.value)}
                                />
                            </div>
                                                
                            <div>
                                <button                                    
                                    onClick={onLogin}                                        
                                >      
                                    Login                                                                  
                                </button>
                            </div>                               
                        </form>

                        <div>
                            <p className='errmsg'>{errmsg}</p>
                        </div>
                       
                       <p>Need access or problems with your account?<br/> <a href="mailto:contact@drdan.me">Contact us</a></p>

                       
                       {/* <p className='bluetext' onClick={createNewAccount}><u>Create new account</u></p> */}



                                                   
                    </div>

    )
}
 
export default Login
