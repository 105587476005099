export const startingSimData = {
    informalE : false,
    cooE: false,
    cosE: false,
    vpE: false,
    ceoE: false,
    progLeadE: false,
    resisterE : false,
    progE1: false,
    progE2: false,
    commsE: false,
    fmcA: false,
    sltA: false,
    macA: false,
    boardA: false,
    supportE: [],
    commsScore: 0,
    memoInProgress: false,
    hashTag: false,
    winCondition: false, 
    currentMonth: 0
}

export const months = ['Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb'];


export function assignDisease() {

    //randomize disease in main app to avoid re-rendering
    const disease2 = {
        name: 'third degree sideburns',
        cause: 'wistful longing for the 1970s',
        symptoms: 'big hair, flares, platforms and glitter make-up',
        treatment: 'talking with a psychiatrist who convinces the patient using up-to-date techniques that these hair accoutrements are rubbish',
        notes: 'The treatment also requires a special diet with a gradual weaning off prawn cocktail and black forest cake.',
        program: 'Mental Health',
        prerequisites: ['Decision Support', 'Medical Staff Office', 'Finance', 'Food Services']
    }

    const disease3 = {
        name: 'bloaty head',
        cause: 'sniffing cheese and drinking unpurified rainwater',
        symptoms: 'very uncomfortable for the sufferer',
        treatment: 'a neurologist pops the swollen head in ambulatory care, and reinflates it with a clever machine to the correct PSI',
        notes: 'A new machine will have to be bought for this purpose and they are noted to be quite finicky and require a lot of maintenance.',
        program: 'Ambulatory Care',
        prerequisites: ['Decision Support', 'Medical Staff Office', 'Finance', 'Facilities']
    }

    const disease4 = {
        name: 'spare ribs',
        cause: 'sitting on cold floors',
        symptoms: 'unpleasant feeling of chestiness',
        treatment: 'a surgeon removes the spare ribs and gives them to the patient in a doggy bag',
        notes: 'This disease has to be diagnosed with a special X-ray.',
        program: 'Surgery',
        prerequisites: ['Decision Support', 'Medical Staff Office', 'Finance', 'Diagnostic Imaging']
    }


    const disease1 = {
        name: 'transparency',
        cause: 'licking the yoghurt from the foil tops of opened pots',
        symptoms: 'renders flesh see-through and horrible',
        treatment: 'a pharmacy drink of specially cooled and coloured water which cures the disease',
        notes: 'The drink is very powerful and has to be administered in a highly contained area of the hospital with special environmental precautions. The service will be run by the medicine program but does not require a physician.',
        program: 'Medicine',
        prerequisites: ['Decision Support', 'Pharmacy', 'Finance', 'Facilities']
    }

    let r = Math.floor(Math.random() * 4)

    let d

    switch (r) {
        case 0:
            d = disease1
            break
        case 1:
            d = disease2
            break
        case 2:
            d = disease3
            break
        case 3:
            d = disease4
            break
        default:
            d = disease1
            break
    }

    return (d)

}