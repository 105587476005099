import { SetChoices } from "../SetChoices";

import { people, programs, supports, meetings, comms, others } from '../Options';

export function SubMenu(props) {

    const { menuOption,
            setMenuOption,
            subOption,
            setSubOption,
            choices,
            setChoices
        } = props;



    let suboptions;

    switch (menuOption) {

        case 'Individual meeting':
            suboptions = people;
            break;
        case 'Program meeting':
            suboptions = programs;
            break;
        case 'Engage support areas':
            suboptions = supports;
            break;
        case 'Attend a formal meeting':
            suboptions = meetings;
            break;
        case 'Communications tactics':
            suboptions = comms;
            break;
        case 'Other actions':
            suboptions = others;
            break;
        default:
            suboptions = people;

    }


    let submenuoptions = suboptions.map(suboption => 
        <button id={suboption}
                onClick={handleClick}
                key={suboption}
                style={{ backgroundColor: suboption===subOption ? 'lightyellow' : ''}}
        
        >{suboption}</button>
    );

    function handleClick(e) {
        setSubOption(e.target.id);

    }


    return(


        <div className='menu'>

        {submenuoptions}

        {subOption !== '' && <SetChoices 
                                subOption={subOption} 
                                setSubOption={setSubOption}
                                setMenuOption={setMenuOption}
                                choices={choices} 
                                setChoices={setChoices}/>}

        </div>

    )


}

