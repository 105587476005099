import { useEffect, useState } from "react";




export function EvaluateChoice(props) {

    // make a copy of current state
    // use this for evaluation logic

    const simdata = props.simdata;
    const currentSimdata = { ...simdata };

    const { 
        choices,
        disease,
        setSbarVisible,
        setSimdata,
        cte
    } = props;


    let resultText;

    const [outputText, setOutputText] = useState('');


    function checkProgram(programToCheck) {
        return (programToCheck === disease.program);
    }

    function checkSupportArea(supportAreaToCheck) {
        return (disease.prerequisites.includes(supportAreaToCheck));
    }



    function arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
    
        a.sort();
        b.sort();
    
        for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
        }
        return true;
    }

    function advocacyReset() {

        currentSimdata = {

            informalE : false,
            cooE: false,
            cosE: false,
            vpE: false,
            ceoE: false,
            progLeadE: false,
            resisterE : false,
            progE1: false,
            progE2: false,
            commsE: false,
            fmcA: false,
            sltA: false,
            macA: false,
            boardA: false,
            supportE: [],
            commsScore: 0,
            memoInProgress: false,
            hashTag: false,
            winCondition: false

        }
    };



    function evaluateChoice(choice, currentSimdata) {
        
        switch(choice) {

            case 'Informal networking':
                resultText = 'You spend some time having some time dropping in on your colleagues and chatting in the line at Starbucks. You learn that there is a real appetite to introduce your clinical service, but that previous efforts to introduce it haven\'t gone so well. There are also a lot of competing priorities at the moment which are reducing organizational bandwidth. Nevertheless, you come away encouraged and cautiously optimistic that this might be the right time to have another go at it. ';
                currentSimdata.informalE = true; //unlock program director/chief meeting
            break;
            
            case 'CEO':
                if (currentSimdata.vpE && currentSimdata.cooE && !currentSimdata.hashTag) {
                    resultText = 'You request a meeting with the CEO. Her administrative assistant emails you back promptly, saying "Certainly, I think Ms Boss has been expecting this request, let me check in with her". The next day you get a call "Ms Boss has a few minutes in her schedule now, is there any chance that you might be able to pop up to her office?". You meet with the CEO and she says "This sounds like a good idea. My advice is to make sure it goes through all the proper approval channels." ';
                    currentSimdata.ceoE = true;
                } else if (currentSimdata.vpE && currentSimdata.cooE & currentSimdata.hashTag) {
                    resultText = 'You request a meeting with CEO. "Yes I saw your social media activity. I\'m sure you had the best of intentions, but honestly it would have been better to have engaged our communications team. We don\'t want confidence in the organization to be undermined. Nevertheless, I\'m happy to support this activity. Just be a bit more careful next time,  social media can be very unpredictable." ';
                    currentSimdata.ceoE = true;
                    currentSimdata.hashTag = false;
                    break;
                } else resultText = 'You request a meeting with the CEO. Her administrative assistant sends you an email back saying "This sounds like a great initiative and Ms Boss would love to meet with you. This month\'s schedule is looking rather hectic, would you mind checking back in again with us next month?"';
            break;

            case 'COO':
                if (currentSimdata.progLeadE) {
                    resultText = '"I appreciate your energy. This sounds like an interesting idea that might have some potential. I\'m not sure how it will fit into our other priorities. Maybe you should meet with Mr Veep?"';
                    currentSimdata.cooE = true; //unlock VP

                } else {
                    resultText = 'You request a meeting with the COO. Mr Runham replies "My schedule is crazy. We\'ve got so many other things on the go. Why don\'t you talk to the program first and see what they have to say? They\'re probably feeling as swamped as I am right now." ';
                }
            break;

            case 'SVP':
                if (currentSimdata.cooE) {
                    resultText = '"Thanks for coming to talk to me. Mr Runham had let me know about this proposal. I really like the idea but we have a lot going on in the organization right now. This would have significant strategic impact for us. My suggestion would be to set up a meeting with the CEO." ';
                    currentSimdata.vpE = true; //unlock CEO
                } else {
                    resultText = 'You request a meeting with the VP. Mr Veep replies "I just don\'t have time right now. Can we chat about this in a few months when the other projects I\'m working on will be done?. This might be something we could look at in the next financial year. Why don\'t you talk to the COO about it?" ';
                }
            break;

            case 'CoS':
                if (currentSimdata.progE2) {
                    resultText = '"Thanks for your efforts on this. It certainly sounds like an initiative that the program feels would contribute to the care of our patients. We can take this to MAC once it has been to SLT." ';
                    currentSimdata.cosE = true; //unlock MAC

                } else {
                    resultText = '"As Chief of Staff my role is to ensure that I can provide the Board with advice and recommendations around the quality of care. This program might help us to advance our organizational objectives, but I would want to know that the program was in agreement before we took this any further. Why don\'t we chat again once you\'ve had an opportunity to present this to the program?" ';
                }
            break;
            
            case 'Ms Smiley/Dr Brain':
                    if (currentSimdata.informalE) {


                        if (checkProgram('Mental Health')) {
                        //If correct program
                        resultText = '"Thanks for coming to talk to us about this idea. It sounds like it might have some legs. This could be something that the program supports, but I\'d suggest that we meet with the program as a whole. I\'m also not sure what Mr Runham would have to say about it." '
                        currentSimdata.progLeadE = true; //unlock COO and program meeting

                        } else {
                        //If not correct program
                        resultText = '"We really appreciate your enthusiam, but I don\'t think we\'re the right program to be talking to about this service."';
                        }



                    } else {
                        resultText = 'You try to set up a meeting with the Chief/Program Director, but the Director says to you "It\'s going to be really difficult to get everyone together this month. Besides, I\'m not really sure what you\'re suggesting makes sense. It just doesn\'t sound like the sort of thing we do around here. Maybe you should try getting to know the culture here before you start changing it." ';
                    }
            break;

            case 'Mr Walker':
                if (currentSimdata.informalE) {


                    if (checkProgram('Ambulatory Care')) {
                    //If correct program
                    resultText = '"Thanks for coming to talk to us about this idea. It sounds like it might have some legs. This could be something that the program supports, but I\'d suggest that we meet with the program as a whole. I\'m also not sure what Mr Runham would have to say about it." '
                    currentSimdata.progLeadE = true; //unlock COO and program meeting

                    } else {
                    //If not correct program
                    resultText = '"We really appreciate your enthusiam, but I don\'t think we\'re the right program to be talking to about this service."';
                    }



                } else {
                    resultText = 'You try to set up a meeting with the Chief/Program Director, but the Director says to you "It\'s going to be really difficult to get everyone together this month. Besides, I\'m not really sure what you\'re suggesting makes sense. It just doesn\'t sound like the sort of thing we do around here. Maybe you should try getting to know the culture here before you start changing it." ';
                }
            break;

            case 'Mr Kuteroff/Dr Blades':
                if (currentSimdata.informalE) {


                    if (checkProgram('Surgery')) {
                    //If correct program
                    resultText = '"Thanks for coming to talk to us about this idea. It sounds like it might have some legs. This could be something that the program supports, but I\'d suggest that we meet with the program as a whole. I\'m also not sure what Mr Runham would have to say about it." '
                    currentSimdata.progLeadE = true; //unlock COO and program meeting

                    } else {
                    //If not correct program
                    resultText = '"We really appreciate your enthusiam, but I don\'t think we\'re the right program to be talking to about this service."';
                    }



                } else {
                    resultText = 'You try to set up a meeting with the Chief/Program Director, but the Director says to you "It\'s going to be really difficult to get everyone together this month. Besides, I\'m not really sure what you\'re suggesting makes sense. It just doesn\'t sound like the sort of thing we do around here. Maybe you should try getting to know the culture here before you start changing it." ';
                }
            break;

            case 'Ms Body/Dr Pepper':
                if (currentSimdata.informalE) {


                    if (checkProgram('Medicine')) {
                    //If correct program
                    resultText = '"Thanks for coming to talk to us about this idea. It sounds like it might have some legs. This could be something that the program supports, but I\'d suggest that we meet with the program as a whole. I\'m also not sure what Mr Runham would have to say about it." '
                    currentSimdata.progLeadE = true; //unlock COO and program meeting

                    } else {
                    //If not correct program
                    resultText = '"We really appreciate your enthusiam, but I don\'t think we\'re the right program to be talking to about this service."';
                    }



                } else {
                    resultText = 'You try to set up a meeting with the Chief/Program Director, but the Director says to you "It\'s going to be really difficult to get everyone together this month. Besides, I\'m not really sure what you\'re suggesting makes sense. It just doesn\'t sound like the sort of thing we do around here. Maybe you should try getting to know the culture here before you start changing it." ';
                }
            break;

            case 'Mental Health':

                if (!checkProgram('Mental Health')) {
                
                    //if not correct program
                    resultText = 'You set up a meeting with the program. The more you talk about your proposed clinical service, the more quizzical looks you get. You ask at the end if anyone has any questions and there is silence. After what feels like an agonizing amount of time, the Director chimes in: "Thank you for taking the time to tell about this service, but to be honest I think we\'re all wondering why you\'ve come to us. This isn\'t really within our scope. It might make sense to talk to another program. Good luck with your idea."';
                    break;

                } else if (!currentSimdata.progLeadE) {

                    //if not chief/program director first
                    resultText = 'You try to set up a meeting with the program, but the Director says to you "It\'s going to be really difficult to get everyone together this month. Besides, I\'m not really sure what you\'re suggesting makes sense."';
                    break;

                } else if (!currentSimdata.progE1) {

                    //correct program, first iteration
                    resultText = 'You set up a meeting with the program. It seems to go well and there is a lot of general support from the group. Almost everyone agrees the clinical service would be a great addition to the organization. The Director is about to close the meeting off, when a voice pipes up. Dr Head, who sitting at the back of the room, raises some serious objections. The rest of the group defer to him. The Director suggests "let\'s take this offline. I think Dr Head makes a good point and I really don\'t see how we\'ll be able to move forward with this. Thanks for coming and presenting to us today, but let\'s put this on the backburner for now" ';
                    currentSimdata.progE1 = true; //unlock Dr Head

                    break;
                } else if (!currentSimdata.resisterE) {

                    resultText = 'Until you address the issues brought up in the last meeting by Dr Head, there doesn\'t seem to be a lot of point in calling the program together for another meeting.';
                    break;

                } else if (currentSimdata.resisterE) {

                    //correct program after Dr Resister engaged
                    resultText = 'You present the proposal at a second program meeting, along with Dr Head. The meeting goes well and Dr Head\'s opinion carries a lot of weight with the group. At the conclusion of the meeting, the program decides to undertake further work on the proposal and seek formal approval from the organization.';
                    currentSimdata.progE2 = true; //unlock FMC, CoS

                    break;

                }

            //default error message if it all falls through
            resultText='***ERROR in program checking sequence***';
            break;

            case 'Ambulatory Care':

            if (!checkProgram('Ambulatory Care')) {
            
                //if not correct program
                resultText = 'You set up a meeting with the program. The more you talk about your proposed clinical service, the more quizzical looks you get. You ask at the end if anyone has any questions and there is silence. After what feels like an agonizing amount of time, the Director chimes in: "Thank you for taking the time to tell about this service, but to be honest I think we\'re all wondering why you\'ve come to us. This isn\'t really within our scope. It might make sense to talk to another program. Good luck with your idea."';
                break;

            } else if (!currentSimdata.progLeadE) {

                //if not chief/program director first
                resultText = 'You try to set up a meeting with the program, but the Director says to you "It\'s going to be really difficult to get everyone together this month. Besides, I\'m not really sure what you\'re suggesting makes sense."';
                break;

            } else if (!currentSimdata.progE1) {

                //correct program, first iteration
                resultText = 'You set up a meeting with the program. It seems to go well and there is a lot of general support from the group. Almost everyone agrees the clinical service would be a great addition to the organization. The Director is about to close the meeting off, when a voice pipes up. Dr Tripman, who sitting at the back of the room, raises some serious objections. The rest of the group defer to him. The Director suggests "let\'s take this offline. I think Dr Tripman makes a good point and I really don\'t see how we\'ll be able to move forward with this. Thanks for coming and presenting to us today, but let\'s put this on the backburner for now" ';
                currentSimdata.progE1 = true; //unlock Dr Tripman

                break;
            } else if (!currentSimdata.resisterE) {

                resultText = 'Until you address the issues brought up in the last meeting by Dr Tripman, there doesn\'t seem to be a lot of point in calling the program together for another meeting.';
                break;

            } else if (currentSimdata.resisterE) {

                //correct program after Dr Resister engaged
                resultText = 'You present the proposal at a second program meeting, along with Dr Tripman. The meeting goes well and Dr Tripman\'s opinion carries a lot of weight with the group. At the conclusion of the meeting, the program decides to undertake further work on the proposal and seek formal approval from the organization.';
                currentSimdata.progE2 = true; //unlock FMC, CoS

                break;

            }

            //default error message if it all falls through
            resultText='***ERROR in program checking sequence***';
            break;

            case 'Surgery':

            if (!checkProgram('Surgery')) {
            
                //if not correct program
                resultText = 'You set up a meeting with the program. The more you talk about your proposed clinical service, the more quizzical looks you get. You ask at the end if anyone has any questions and there is silence. After what feels like an agonizing amount of time, the Director chimes in: "Thank you for taking the time to tell about this service, but to be honest I think we\'re all wondering why you\'ve come to us. This isn\'t really within our scope. It might make sense to talk to another program. Good luck with your idea."';
                break;

            } else if (!currentSimdata.progLeadE) {

                //if not chief/program director first
                resultText = 'You try to set up a meeting with the program, but the Director says to you "It\'s going to be really difficult to get everyone together this month. Besides, I\'m not really sure what you\'re suggesting makes sense."';
                break;

            } else if (!currentSimdata.progE1) {

                //correct program, first iteration
                resultText = 'You set up a meeting with the program. It seems to go well and there is a lot of general support from the group. Almost everyone agrees the clinical service would be a great addition to the organization. The Director is about to close the meeting off, when a voice pipes up. Dr Hacker, who sitting at the back of the room, raises some serious objections. The rest of the group defer to him. The Director suggests "let\'s take this offline. I think Dr Hacker makes a good point and I really don\'t see how we\'ll be able to move forward with this. Thanks for coming and presenting to us today, but let\'s put this on the backburner for now" ';
                currentSimdata.progE1 = true; //unlock Dr Tripman

                break;
            } else if (!currentSimdata.resisterE) {

                resultText = 'Until you address the issues brought up in the last meeting by Dr Hacker, there doesn\'t seem to be a lot of point in calling the program together for another meeting.';
                break;

            } else if (currentSimdata.resisterE) {

                //correct program after Dr Resister engaged
                resultText = 'You present the proposal at a second program meeting, along with Dr Hacker. The meeting goes well and Dr Hacker\'s opinion carries a lot of weight with the group. At the conclusion of the meeting, the program decides to undertake further work on the proposal and seek formal approval from the organization.';
                currentSimdata.progE2 = true; //unlock FMC, CoS

                break;

            }

            //default error message if it all falls through
            resultText='***ERROR in program checking sequence***';
            break;

            case 'Medicine':

            if (!checkProgram('Medicine')) {
            
                //if not correct program
                resultText = 'You set up a meeting with the program. The more you talk about your proposed clinical service, the more quizzical looks you get. You ask at the end if anyone has any questions and there is silence. After what feels like an agonizing amount of time, the Director chimes in: "Thank you for taking the time to tell about this service, but to be honest I think we\'re all wondering why you\'ve come to us. This isn\'t really within our scope. It might make sense to talk to another program. Good luck with your idea."';
                break;

            } else if (!currentSimdata.progLeadE) {

                //if not chief/program director first
                resultText = 'You try to set up a meeting with the program, but the Director says to you "It\'s going to be really difficult to get everyone together this month. Besides, I\'m not really sure what you\'re suggesting makes sense."';
                break;

            } else if (!currentSimdata.progE1) {

                //correct program, first iteration
                resultText = 'You set up a meeting with the program. It seems to go well and there is a lot of general support from the group. Almost everyone agrees the clinical service would be a great addition to the organization. The Director is about to close the meeting off, when a voice pipes up. Dr Hertz, who sitting at the back of the room, raises some serious objections. The rest of the group defer to him. The Director suggests "let\'s take this offline. I think Dr Hertz makes a good point and I really don\'t see how we\'ll be able to move forward with this. Thanks for coming and presenting to us today, but let\'s put this on the backburner for now" ';
                currentSimdata.progE1 = true; //unlock Dr Tripman

                break;
            } else if (!currentSimdata.resisterE) {

                resultText = 'Until you address the issues brought up in the last meeting by Dr Hertz, there doesn\'t seem to be a lot of point in calling the program together for another meeting.';
                break;

            } else if (currentSimdata.resisterE) {

                //correct program after Dr Resister engaged
                resultText = 'You present the proposal at a second program meeting, along with Dr Hertz. The meeting goes well and Dr Hertz\'s opinion carries a lot of weight with the group. At the conclusion of the meeting, the program decides to undertake further work on the proposal and seek formal approval from the organization.';
                currentSimdata.progE2 = true; //unlock FMC, CoS

                break;

            }

            //default error message if it all falls through
            resultText='***ERROR in program checking sequence***';
            break;

            case 'Dr Head':

            if (!checkProgram('Mental Health')) {

                    //if not correct program
                    resultText = '"I\'m not sure why you are meeting with me, I don\'t have anything to do with this. You\'d be better to meet someone in the program."';
                    break;

            } else if(!currentSimdata.progLeadE) {

                    //if not engaged with program already
                    resultText = '"I\'m not sure why you are meeting with me. I\'m just a physician in the program. Your best bet would be to meet with the program leadership first. They\'re the ones with the power."';
                    break;
            }


            //If correct and already met with prograqm
            resultText = 'You spend an hour and a half with Dr Head. In the 1:1 setting. Although he has a number of objections to your proposal, you are able to skilfully persuade him of your perspective. He eventually agrees to provide his support to the development of the new clinical service, and suggests taking it back to another program meeting. You ask whether he might be willing to be part of the presentation and he commits to co-presenting with you.';
            currentSimdata.resisterE = true; //unlock second program meeting

                
            break;

            case 'Dr Tripman':

            if (!checkProgram('Ambulatory Care')) {

                    //if not correct program
                    resultText = '"I\'m not sure why you are meeting with me, I don\'t have anything to do with this. You\'d be better to meet someone in the program."';
                    break;

            } else if(!currentSimdata.progLeadE) {

                    //if not engaged with program already
                    resultText = '"I\'m not sure why you are meeting with me. I\'m just a physician in the program. Your best bet would be to meet with the program leadership first. They\'re the ones with the power."';
                    break;
            }


            //If correct and already met with prograqm
            resultText = 'You spend an hour and a half with Dr Tripman. In the 1:1 setting. Although he has a number of objections to your proposal, you are able to skilfully persuade him of your perspective. He eventually agrees to provide his support to the development of the new clinical service, and suggests taking it back to another program meeting. You ask whether he might be willing to be part of the presentation and he commits to co-presenting with you.';
            currentSimdata.resisterE = true; //unlock second program meeting

                
            break;

            case 'Dr Hacker':

            if (!checkProgram('Surgery')) {

                    //if not correct program
                    resultText = '"I\'m not sure why you are meeting with me, I don\'t have anything to do with this. You\'d be better to meet someone in the program."';
                    break;

            } else if(!!currentSimdata.progLeadE) {

                    //if not engaged with program already
                    resultText = '"I\'m not sure why you are meeting with me. I\'m just a physician in the program. Your best bet would be to meet with the program leadership first. They\'re the ones with the power."';
                    break;
            }


            //If correct and already met with prograqm
            resultText = 'You spend an hour and a half with Dr Hacker. In the 1:1 setting. Although he has a number of objections to your proposal, you are able to skilfully persuade him of your perspective. He eventually agrees to provide his support to the development of the new clinical service, and suggests taking it back to another program meeting. You ask whether he might be willing to be part of the presentation and he commits to co-presenting with you.';
            currentSimdata.resisterE = true; //unlock second program meeting

                
            break;

            case 'Dr Hertz':

            if (!checkProgram('Medicine')) {

                    //if not correct program
                    resultText = '"I\'m not sure why you are meeting with me, I don\'t have anything to do with this. You\'d be better to meet someone in the program."';
                    break;

            } else if(!currentSimdata.progLeadE) {

                    //if not engaged with program already
                    resultText = '"I\'m not sure why you are meeting with me. I\'m just a physician in the program. Your best bet would be to meet with the program leadership first. They\'re the ones with the power."';
                    break;
            }


            //If correct and already met with prograqm
            resultText = 'You spend an hour and a half with Dr Hertz. In the 1:1 setting. Although he has a number of objections to your proposal, you are able to skilfully persuade him of your perspective. He eventually agrees to provide his support to the development of the new clinical service, and suggests taking it back to another program meeting. You ask whether he might be willing to be part of the presentation and he commits to co-presenting with you.';
            currentSimdata.resisterE = true; //unlock second program meeting

                
            break;

            case 'Write a Memo':

                    if (currentSimdata.commsE) {
                        resultText = 'You work with the comms team to create a memo. They help you ensure the messaging is clear and that you are sending it to the right people in the organization. The next day when you come into work you overhear some people talking excitedly about your proposed clinical service, so you are confident that the message is starting to get to your audience. ';
                        currentSimdata.commsScore += 1; // add to comms score

                    } else {
                        //If not spoken to comms first
                        resultText = 'You start work on writing a memo. It takes a couple of hours to compose your thoughts and by the end you\'re really happy with what you have put together. However, you\'re not sure how to best send it out and realize you should check in with the communications team.';
                        currentSimdata.memoInProgress = true;

                    }
                
                break;

            case 'Present at Management Meeting':

                    if (currentSimdata.commsE) {
                        resultText = 'You work with the comms team to prepare a presentation for management meeting. They ensure that your messaging is clear and relevant to the audience, which consists of managers and directors. Your presentation is very successful and  you get several positive feedback emails after the meeting.';
                        currentSimdata.commsScore += 1; // add to comms score

                    } else {
                    // if not spoken to comms first
                        resultText = 'You start putting together a PowerPoint presentation for management meeting, but quickly realize that it would be helpful to seek advice from the commmunications team first.';
                    }

                break;

            case 'Hold a Town Hall':

                if (!currentSimdata.winCondition) {
                    resultText = 'You work on setting up an organization town hall. The SVP hears about your plans. "Steady on, you can\'t just set up a town hall by yourself. It\'s going to clash with our scheduled management meeting and there\'s a lot on the agenda already. This proposal hasn\'t been approved by the Board yet either, we mustn\'t get ahead of that.';
                    }
                    else {
                        resultText = 'You hold a Town Hall following approval by the Board. There is huge excitement about the new clinical service from the larger organization. Congratulations!';
                    }
                    break;

            case 'Communications':
                    
                    resultText = 'You set up a meeting with Communications. They are enthusiastic about your idea. "It\'ll be really important to make sure you let people in the organization know about this. You won\'t be able to get this approved unless everyone is sure there has been enough communication. We can help with any communication tactics that you need." ';
                    
                    currentSimdata.commsE = true;


                    if (currentSimdata.memoInProgress) {
                        resultText += 'The memo you\'ve put together looks good. We\'ve made a few suggested changes and we\'ll send it out for you now.';
                        currentSimdata.commsScore += 1; // add to comms score now memo sent

                    }

                break;
            
            case 'Decision Support':

                resultText = 'You set up a meeting with Decision Support. "Thanks for coming to talk to us. Sometimes people don\'t think about the need for data until it\'s too late and then everyone has to scramble to try and make things work. Here is some information from our area to support your proposal which you can include in your Briefing Note below."';
                currentSimdata.supportE = [...currentSimdata.supportE, 'Decision Support'];


            break;

            case 'Finance':

                resultText = 'You set up a meeting with Finance. "Thanks for coming to talk to us. Sometimes people don\'t realize that we do a lot more than just accounting. Your Business Operations Consultant can help you consider the financial impact of decision-making, and to create a business case for services. Here is some information from our area to support your proposal which you can include in your Briefing Note below."';

                currentSimdata.supportE = [...currentSimdata.supportE, 'Finance'];

                setSbarVisible(true);
    

            break;

            case 'Facilities':

                    // check if relevant to clinical service
                    if (checkSupportArea('Facilities')) {
                        resultText = 'You set up a meeting with Facilities. "Thanks for coming to talk to us. Sometimes people don\'t realize that we do a lot more than just parking. We take care of managing space, asssts, capital infrastructure, and maintenance. Here is some information from our area to support your proposal which you can include in your Briefing Note below."';
                        //supportE.push('Facilities');
                        currentSimdata.supportE = [...currentSimdata.supportE, 'Facilities'];

                    } else {
                        resultText = 'You set up a meeting with Facilities. Although they are appreciative (you rather get the sense that they often feel forgotten about until a decision has already been made), they are not sure how relevant they are to your current proposal. They suggest you might want to talk to some of the other support areas that might be more important for this project.';
                    }
            break;

            case 'Food Services':

            // check if relevant to clinical service
            if (checkSupportArea('Food Services')) {
                resultText = 'You set up a meeting with Food Services. "Thanks for coming to talk to us. Sometimes people don\'t realize the scope of what we do. We take care of all patient food services, including spedial diets, allergies and restrictions. Here is some information from our area to support your proposal which you can include in your Briefing Note below."';
                //supportE.push('Food Services');
                currentSimdata.supportE = [...currentSimdata.supportE, 'Food Services'];

                setSbarVisible(true);
            } else {
                resultText = 'You set up a meeting with Food Services. Although they are appreciative (you rather get the sense that they often feel forgotten about until a decision has already been made), they are not sure how relevant they are to your current proposal. They suggest you might want to talk to some of the other support areas that might be more important for this project.';
            }
    break;

            case 'Medical Staff Office':

            // check if relevant to clinical service
            if (checkSupportArea('Medical Staff Office')) {
                resultText = 'You set up a meeting with the Medical Staff Office. "Thanks for coming to talk to us. We can support anything to do with physicians, midwives and dentists. As well as helping with recruitment, we support all their formal processes including credentialing and appointments. Here is some information from our area to support your proposal which you can include in your Briefing Note below."';
                //supportE.push('Medical Staff Office');
                currentSimdata.supportE = [...currentSimdata.supportE, 'Medical Staff Office'];

                setSbarVisible(true);
            } else {
                resultText = 'You set up a meeting with the Medical Staff Office. Although they are appreciative (you rather get the sense that they often feel forgotten about until a decision has already been made), they are not sure how relevant they are to your current proposal. They suggest you might want to talk to some of the other support areas that might be more important for this project.';
            }
            break;

            case 'Diagnostic Imaging':

            // check if relevant to clinical service
            if (checkSupportArea('Diagnostic Imaging')) {
                resultText = 'You set up a meeting with Diagnostic Imaging. "Thanks for coming to talk to us. Although programs often rely on diagnostic imaging, they don\'t always think about the impacts. Extra X-rays, scans, and other diagnostic imaging tests all need resourcing. Here is some information from our area to support your proposal which you can include in your Briefing Note below."';
                //supportE.push('Diagnostic Imaging');
                currentSimdata.supportE = [...currentSimdata.supportE, 'Diagnostic Imaging'];

                setSbarVisible(true);

            } else {
                resultText = 'You set up a meeting with Diagnostic Imaging. Although they are appreciative (you rather get the sense that they often feel forgotten about until a decision has already been made), they are not sure how relevant they are to your current proposal. They suggest you might want to talk to some of the other support areas that might be more important for this project.';
            }
            break;

            case 'Pharmacy':

            // check if relevant to clinical service
            if (checkSupportArea('Pharmacy')) {
                resultText = 'You set up a meeting with Pharmacy. "Thanks for coming to talk to us. Programs can often underestimate the impact on pharmacy. The logistics of procuring, supplying and dispensing medications safely needs a lot of work behind the scenes. Here is some information from our area to support your proposal which you can include in your Briefing Note below."';
                //supportE.push('Pharmacy');
                currentSimdata.supportE = [...currentSimdata.supportE, 'Pharmacy'];

                setSbarVisible(true);
            } else {
                resultText = 'You set up a meeting with Pharmacy. Although they are appreciative (you rather get the sense that they often feel forgotten about until a decision has already been made), they are not sure how relevant they are to your current proposal. They suggest you might want to talk to some of the other support areas that might be more important for this project.';
            }
            break;

            case 'FMC':
                
                // if program not fully engaged
                if (!currentSimdata.progE2) {
                    resultText = 'You request to attend the Financial Management Committee meeting (FMC). This is the internal management committee comprised of hospital leadership that makes decisions about resource allocation (including financial resources). You get a reply back from the administrative assistant responsible for coordinating the meeting: "All requests to come to FMC should be endorsed by the program, and you should submit an Briefing Note with the request. Once you\'ve got these in place, let me know and we\'ll send you an invitation". ';
                    break;
                }

                else {
                    resultText = 'You are put on the agenda for the FMC meeting. The administrative assistant responsible for coordinating the meeting says "We\'ll need your Briefing Note. Can you send something into me right now? Make sure you include all relevant information." ';


                    //test if all prerequisites met
                    if (arraysEqual(disease.prerequisites, currentSimdata.supportE)) {
                        resultText += 'Your proposal was subsquently discussed at the FMC meeting. Note was made of your comprehensive Briefing Note. FMC approved your proposal and suggest that you send it along to SLT.';
                        currentSimdata.fmcA = true;

                        break;
                    } else {
                        resultText += 'Your proposal was subsquently discussed at the FMC meeting. Although there was support for the idea, you are provided with feedback to help refine the proposal further. "This seems like a good idea, but FMC would like to have more information from relevant support areas. Could you revise the Briefing Note and request to come back to FMC when you are ready with this?" ';
                        break;
                    }

                }

            case 'SLT':

                // if FMC not approved
                if (!currentSimdata.fmcA) {
                    resultText = 'You request to attend the Senior Leadership Team (SLT) meeting. This is a group of the senior organizational leaders that meet to make decisions which have strategic implications for the organization. The meetings are coordinated the CEO\'s executive assistant. She replies to your request saying "The CEO would expect this proposal to go to FMC first to ensure that we have resources available for it. Can you check back in with me once FMC has approved it?" ';
                    break;
                } else {
                    resultText = 'Your proposal is discussed at the SLT meeting. "This sounds like a really interesting idea. Because it is a new service, we should take it to the Board for approval. Before we do that though, it\'ll be important to make sure we have adequately communicated within the organization and as it is a clinical service and it will also need to go to MAC.';
                    //sltA = true;
                    currentSimdata.sltA = true

                    break;
                }

            case 'MAC':

            // if COS not approved
            if (!currentSimdata.cosE) {
                resultText = 'You ask to be put on the agenda for the Medical Advisory Committee meeting. This group is a formally constituted committee and is required under the Public Hospitals Act. It reports to the Board of Directors and provides advice to Chief of Staff. The Chief of Staff\'s executive assistant responds to your email "Thanks for your note but is Dr Topp even aware of this? Let me know when you\'ve had chance to talk to them if it is approved for inclusion." ';
                break;
            } else if (!currentSimdata.sltA) {         // if SLT not approved
                resultText = 'You ask to be put on the agenda for the Medical Advisory Committee meeting. This group is a formally constituted committee and is required under the Public Hospitals Act. It reports to the Board of Directors and provides advice to Chief of Staff. The Chief of Staff\'s executive assistant responds to your email "Thanks for your note, but we wouldn\'t normally take this to MAC until SLT has approved it and confirmed that resources are available. Can you let me know when that\'s done?"';
                break;
            } else {
                resultText = 'Your proposal is included in the MAC agenda. It is met with an enthusiastic reception. Interestingly, several MAC members report that they have discussed the proposal with the physician who objected at the program meeting, and who has actually turned out to be your biggest physician champion. A motion is passed by MAC to recommend the proposed clinical service to the Board of Directors. ';
                currentSimdata.macA = true;

                break;
            }

            case 'Board of Directors':

            // check CEO approval
            if(!currentSimdata.ceoE) {

                resultText = 'You request to attend the Board of Directors meeting. The Board Liaison says "I\'m not sure this is ready for the Board yet. You need to make sure the CEO agrees to this proposal." ';
                break;

            } else if (!currentSimdata.sltA) {
                
                resultText = 'You request to attend the Board of Directors meeting. The Board Liaison says "I\'m not sure this is ready for the Board yet. You need to make sure you have followed all the proper processes and secured approvals (including SLT) before this comes to them." ';
                break;

            } else if (!currentSimdata.macA) { //check MAC approval
                
                resultText = 'You request to attend the Board of Directors meeting. The Board Liaison says "I\'m not sure this is ready for the Board yet. You need to make sure you have followed all the proper processes and secured approvals (including MAC) before this comes to them." ';
                break;

            } else if (currentSimdata.commsScore < 2) {  //check communicationsscore
                
                resultText = 'You request to attend the Board of Directors meeting. The Board Liaison says "My understanding is that SLT wanted to ensure there had been sufficient communication within the organization before this came to the Board for approval. Why don\'t you get back to me when you are ready?" ';
                break;

            } else {
                resultText = 'You ask to attend the Board of Directors meeting and are put on the agenda. The Board liaison says "You\'ll need to create a motion for the Board to approve this proposal. Can you get that to me now?" ';
                resultText += 'You prepare the motion and the Board meets. Congratulations! Your new clinical service is formally approved by the Board of Directors and will be started in the upcoming financial year.';
                currentSimdata.winCondition = true;

                break;
            }

            case 'Grassroots advocacy':

                resultText = 'You decide to initiate grassroots advocacy by encouraging patients to write to the CEO and the Board in support of your proposal. An article appears in the local newspaper and is picked up by the Toronto Star. The media attention paints the organization in an unfavourable light and undermines community confidence. Ultimately, this undoes all of the progress you have made in advancing the initiative and you\'ll have to start again. ';
                advocacyReset();

            break;
            
            case 'Personal social media':

            resultText = 'You post detailed information about your proposed clinical service on your personal social media campaign. You try to get a hashtag trending, but ultimately it doesn\'t garner much interest. You hear that your social media came to the CEO\'s attention and that she wasn\'t very impressed. If you want to proceed further, you\'ll likely have to obtain her support again. ';
            currentSimdata.ceoE = false; //remove CEO support

            currentSimdata.hashTag = true;

            break;

            default:

            break;




        }

        //console.log(resultText);


        return([resultText, currentSimdata]);


    }


    let [result1, newSimdata] = evaluateChoice(choices.first, currentSimdata);
    let [result2, newNewSimdata] = evaluateChoice(choices.second, newSimdata);




    
    useEffect(() => {
            //TODO: copy back simdata and update state
        setSimdata(newNewSimdata);

        

        if (currentSimdata.supportE.length > 0) setSbarVisible(true);

    }, [result2]);
    
    






    return(
        <div>
        <div id='results'>
        <h4>Action 1</h4>
        <p>{result1}</p>
       </div>
      <div id='results2'>
        <h4 id="action2">Action 2</h4>
        <p>{result2}</p>
        </div>
        </div>
    )

}

