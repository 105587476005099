import { useState } from "react";



export function Sbar(props) {

    const disease = props.disease;
    const simdata = props.simdata;
    const [sbarStatus, setsbarStatus] = useState('DRAFT');






    return (

        <div id="sbarcontainer" className="shadow">
            <h3 id="sbartitle">Briefing Note -&nbsp;  
            {sbarStatus === 'DRAFT' ? <span style={{color: 'green'}}>{sbarStatus}</span> :
                                        <span style={{color: 'red'}}>{sbarStatus}</span>}
            
            
            </h3>
            <h4>Situation</h4>
                <p>We propose to establish a new clinical service within Odysseus Healthcare to address <span id='sbardiseasename'>{disease.name}</span>.<br/> <br/>
                    This is caused by <span id='sbardiseasecause'>{disease.cause}</span> and leads to symptoms of <span id='sbardiseasesymptoms'>{disease.symptoms}</span>.<br/><br/>
                    The treatment is <span id='sbardiseasetreatment'>{disease.treatment}</span>.<br/><br/>
                </p>
            <h4>Background</h4>
            <p><span id='sbardiseasenotes'></span>{disease.notes}</p><br/><br/>
            <h4>Assessment (Relevant Support Areas Engaged)</h4>
            <ul>
                {simdata ? simdata
                        .map(area => <li key={area}> {area} </li>) : null}
            </ul>
            <br/><br/>   
            <h4>Recommendation</h4>
            <p>Establish this clinical service as described. Once FMC has approved, for next steps, the proposal will be submitted to SLT and MAC, before being presented to the Board.</p>
        </div>




    )

}