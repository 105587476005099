import React, {useState} from 'react';
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth } from './firebase';
 
const Signup = (props) => {
    //const navigate = useNavigate();


 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [errmsg, setErrmsg] = useState('');

    const { setNewAccountShowing,
            setIsLoggedIn } = props;
 
    const onSubmit = async (e) => {
      e.preventDefault()
     
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            setIsLoggedIn(true);



        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setErrmsg(errorMessage);

            // ..
        });


 
   
    }

    const onAlreadyHaveAccountClick = () => {
        setNewAccountShowing(false);
    }
 
  return (
             <div id='signupcontainer' className='shadow'>                                                               
                    <h2> Create New Account</h2>                                                                            
                    <form>                                                                                            
                        <div>
                            <label htmlFor="email-address">
                                Email address &nbsp;
                            </label>
                            <input
                                type="email"
                                label="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}  
                                required                                    
                                placeholder="Email address"                                
                            />
                        </div>

                        <div>
                            <label htmlFor="password">
                                Password &nbsp;
                            </label>
                            <input
                                type="password"
                                label="Create password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} 
                                required                                 
                                placeholder="Password"              
                            />
                        </div>                                             
                        
                        <button
                            type="submit" 
                            onClick={onSubmit}                        
                        >  
                            Sign up                                
                        </button>
                                                                     
                    </form>


                    <div>
                            <p className='errmsg'>{errmsg}</p>
                        </div>
                   
                    <p className='bluetext' onClick={onAlreadyHaveAccountClick}>
                        <u>Already have an account?</u>
                    </p>                   
                </div>
  )
}
 
export default Signup